import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import s from './styles.module.css'

const ProductTitle = ({ className, subTitleClassName, children }) => {
  if (!children) return null
  const titleParts = children.split(' | ')
  return (
    <>
      <h2 className={classNames(s.title, className)}>{titleParts[0]}</h2>
      {titleParts.length > 1 && (
        <p className={classNames(s.subTitle, subTitleClassName)}>
          {titleParts.slice(1).join(' | ')}
        </p>
      )}
    </>
  )
}

ProductTitle.propTypes = {
  className: PropTypes.string,
  subTitleClassName: PropTypes.string,
  children: PropTypes.node,
}
ProductTitle.defaultProps = {
  className: '',
  subTitleClassName: '',
  children: null,
}

export default ProductTitle
