import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

const getDeviceConfig = (width) => {
  if (width <= 640) {
    return 'xs'
  } else if (width >= 900 && width < 1441) {
    return 'sm'
  } else if (width >= 1441 && width < 1921) {
    return 'md'
  } else if (width >= 1921) {
    return 'lg'
  }
}

const useBreakpoint = () => {
  const hasWindow = typeof window !== 'undefined' && window

  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(hasWindow && window.innerWidth)
  )

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(hasWindow && window.innerWidth))
    }, 200)
    hasWindow && window.addEventListener('resize', calcInnerWidth)
    return () =>
      hasWindow && window.removeEventListener('resize', calcInnerWidth)
  }, [hasWindow])

  return brkPnt
}
export default useBreakpoint
