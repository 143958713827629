import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import parseShopifyMetafield from 'helpers/parseShopifyMetafield'

import s from './styles.module.css'

const ProductPrice = ({ data, className }) => {
  if (data.variants.length === 0 || !data.variants[0].metafields) return null

  let price = ''

  const rrp = parseShopifyMetafield(data.variants[0].metafields, 'rrp')
  if (!rrp || rrp.length === 0) return null
  price = rrp[0].value

  if (data.variants.length < 2) {
    return (
      <span className={classNames(s.container, className)}>£{price} RRP</span>
    )
  }

  const variantPrices = data.variants
    .map((v) => {
      const rrp = parseShopifyMetafield(v.metafields, 'rrp')
      if (!rrp || rrp.length === 0) return null
      return rrp[0].value
    })
    .filter((p) => p)
  const lowestVariantPrice = variantPrices.reduce(
    (acc, cur) => (acc === null || cur < acc ? cur : acc),
    null
  )

  price = variantPrices.length > 1 ? `From £${lowestVariantPrice}` : price

  return price ? (
    <span className={classNames(s.container, className)}>{price} RRP</span>
  ) : null
}

// todo: setup proper object being received
ProductPrice.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}
ProductPrice.defaultProps = {
  data: {},
  className: '',
}

export default ProductPrice
