const parseShopifyMetafield = (metafields, fieldKey) => {
  const recordFound = (metafields.edges
    ? metafields.edges.map((e) => e.node)
    : Object.values(metafields)
  ).find((m) => m.key === fieldKey)
  if (!recordFound) return null

  // this might be removed later if we've found a better methodology for fetching the relationship
  if (recordFound.relationshipItems) return recordFound.relationshipItems

  if (recordFound.type.toLowerCase() === 'string') {
    if (recordFound.key === 'washes') return recordFound.value.split('###')

    return recordFound.value
  }

  if (recordFound.type === 'json') {
    const parsedValue = JSON.parse(recordFound.value)
    // if (!parsedValue) {
    //   console.error("Couldn't parse Shopify metafield value", recordFound)
    //   return null
    // }
    return parsedValue
  }

  if (recordFound.type === 'JSON_STRING') {
    const parsedValue = JSON.parse(recordFound.value)
    if (!parsedValue.rows) {
      console.error("Couldn't parse Shopify metafield value", recordFound)
      return null
    }

    return parsedValue.rows.map((row) =>
      row.map((cell) => ({
        ...cell,
        value: parseShopifyMetafield(metafields, cell.key),
      }))
    )
  }

  return null
}

export default parseShopifyMetafield
