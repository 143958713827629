import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import ProductTitle from 'components/ProductTitle'
import ProductPrice from 'components/ProductPrice'

import s from './styles.module.css'

const ProductItem = ({ data, className, detailsClassName }) => {
  const { handle, title, images, variants } = data

  return (
    <Link
      to={`/product/${handle}`}
      className={classNames(s.container, className)}
    >
      <div className={s.imgWrap}>
        {images[0]?.localFile && (
          <GatsbyImage
            className={s.img}
            image={getImage(images[0].localFile)}
            alt=""
            backgroundColor="#eee"
            style={{ position: 'absolute' }}
          />
        )}
      </div>
      <div className={classNames(s.details, detailsClassName)}>
        <ProductTitle>{title}</ProductTitle>
        {variants && <ProductPrice data={data} />}
      </div>
    </Link>
  )
}

ProductItem.propTypes = {
  data: PropTypes.shape({
    handle: PropTypes.string.isRequired,
    title: PropTypes.string,
    metafields: PropTypes.array,
    variants: PropTypes.array,
    images: PropTypes.array,
  }),
  className: PropTypes.string,
  detailsClassName: PropTypes.string,
}
ProductItem.defaultProps = {
  data: {},
  className: '',
  detailsClassName: '',
}

export default ProductItem
